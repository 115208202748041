import React, { FC } from 'react';
import {Grid} from '@mui/material';
import { Desktop } from 'modules/theme/components/responsive';
import {useListing} from 'components/page/search/map/hooks/searchResult/listing/useListing';
import {Headline} from 'components/page/search/map/components/searchResult/listing/headline/Headline';
import {Pagination} from 'components/page/search/map/components/searchResult/listing/pagination/Pagination';
import {NoData} from 'components/page/search/map/components/searchResult/listing/advertisemensList/noData/NoData';
import {
    LoadingContent
} from 'components/page/search/map/components/searchResult/listing/advertisemensList/loading/LoadingContent';
import {
    AdvertisementsList
} from 'components/page/search/map/components/searchResult/listing/advertisemensList/AdvertisementsList';

interface IListing {
    locationTitle: string
}

export const Listing: FC<IListing> = ({locationTitle}) => {

    const {
        items,
        noData,
        isLoading,
        isFetching,
        currentPage,
        totalPageCount,
        listOfResultRef,
        handlePageChange,
    } = useListing();

    if (noData) return <NoData/>;
    if (isLoading) return <LoadingContent/>;

    return <Grid container spacing={3} ref={listOfResultRef} alignSelf="start" py={2}>

        <Desktop>
            <Grid item xs={12}>
                <Headline locationTitle={locationTitle}/>
            </Grid>
        </Desktop>

        <Grid item xs={12}>
            <AdvertisementsList currentPage={currentPage} items={items} />
        </Grid>

        <Grid item xs={12} container justifyContent="center">
            <Pagination
                isDisabled={isFetching}
                currentPage={currentPage}
                onChange={handlePageChange}
                totalPageCount={totalPageCount}
            />
        </Grid>
    </Grid>;
};
